import React from 'react';

export const Overlays = {
    SEARCH: 'search',
    SEARCH_LOGS: 'search_logs',
    USER_DROPDOWN: 'user_dropdown',
    SEARCH_STAT: 'search_stat',
    STAT_FILTER: 'stat_filter',
};

const OverlayContext = React.createContext({});
export default OverlayContext;

import { css } from '@emotion/core';

export const informationIsConfidentialFooterCSS = ({ colors }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: ${colors.textSecondary};
    width: fit-content;
    margin: auto;
`;

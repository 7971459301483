import ApiError, { ApiErrorType } from './api/ApiError';
import {
    showAPINotImplementedNotification,
    showInternalErrorNotification,
    showInternalErrorWithUUIDNotification,
    showUnexpectedErrorNotification,
} from './notifications';

export default function postProcessApiResponse(response) {
    if (response.responseStatus === 'SUCCESS') {
        return response.data;
    }

    if (!response.errors?.length && response.responseStatus === 'FAILURE') {
        if (response.responseMessage) {
            // eslint-disable-next-line no-console
            console.error(response.responseMessage);
        }
        showUnexpectedErrorNotification();
    }

    response.errors?.forEach((e) => {
        switch (e.type) {
            case 'INTERNAL_SERVER_ERROR':
                if (e.uuid) {
                    showInternalErrorWithUUIDNotification(e.uuid);
                } else {
                    showInternalErrorNotification();
                }
                break;
            case 'NOT_FOUND':
                showAPINotImplementedNotification();
                break;
            default:
                showUnexpectedErrorNotification();
                break;
        }
    });

    throw new ApiError(ApiErrorType.SERVER_ERROR);
}
